import LoginScreen from '../../../common/components/login-screen';
import { NotFoundPage } from '../../../common/containers/not-found-page';
import {
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_POST,
  ROUTE_LAST_POST,
  ROUTE_POST_ADVANCED_SLUG,
} from '../../constants/routes';
import LastPostPage from '../last-post-page';
import PostEditorPageOrModal from '../post-editor-page-or-modal/lazy-loaded';
import PostComponent from '../post-page';
import { WrapComponent } from './router-wrapper';

export const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: WrapComponent(NotFoundPage),
  [ROUTE_LOGIN]: WrapComponent(LoginScreen),
  [ROUTE_CREATE_POST]: WrapComponent(PostEditorPageOrModal),
  [ROUTE_EDIT_POST]: WrapComponent(PostEditorPageOrModal),
  [ROUTE_PREVIEW_POST]: WrapComponent(PostComponent),
  [ROUTE_POST]: WrapComponent(PostComponent),
  [ROUTE_POST_ADVANCED_SLUG]: WrapComponent(PostComponent),
  [ROUTE_LAST_POST]: WrapComponent(LastPostPage),
};
